import MiniProjectRouteWidget from './MiniProject/RouteWidget'
// import RouteWidget from './Dashboard/RouteWidget'
// import Payment from './Payment';

function App() {
  return (
    <MiniProjectRouteWidget />
  );
}

export default App;
